<template>
  <div ref="scrollToMe" class="role-select">
    <div @click="$router.push({ name: 'home' })" class="role-select__header">
      <svg-icon class="home__pixel" name="home_pixel"></svg-icon>
      <h3>Демогратія</h3>
    </div>
    <div class="role-select__cards">
      <div
        v-for="card in cards"
        class="role-select__card"
        :class="{ active: getActiveGame === card.id }"
        :key="card.id"
        @click="selectRole(card.id)"
      >
        <img class="role__icon" :src="`./${card.icon}.png`" alt="" />
        <p class="card__title">{{ card.title }}</p>
        <p class="card__description">{{ card.description }}</p>
        <div v-if="getActiveGame === card.id" class="card__start">
          Почати гру
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      level: 0,
      cards: [
        {
          id: 0,
          title: "Громадянин",
          description:
            "Кожен із нас — громадянин чи громадянка. Давайте перевіримо, наскільки грамотно ви будете діяти під час війни, та дізнаємося важливі речі про особисту безпеку.",
          icon: "role_0",
        },
        {
          id: 1,
          title: "Волонтер",
          description:
            "Кожен волонтер знає, як шукати, знаходити, відправляти і допомагати — і це все надзвичайно важливо. Але і тут треба не забувати про правила безпеки. Давайте дізнаємося про це більше.",
          icon: "role_1",
        },
        {
          id: 2,
          title: "Голова гумштабу",
          description:
            "У 2022 волонтерство стало чи не головним заняттям усіх українців і українок. Дехто очолив волонтерські центри та гуманітарні штаби. Втім, з великою силою приходить і велика відповідальність. Давайте перевіримо, чи готові ви до неї.",
          icon: "role_2",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getActiveGame"]),
  },
  created() {},
  updated() {
    const el = this.$refs.scrollToMe;
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  },
  mounted() {
    const el = this.$refs.scrollToMe;
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  },
  methods: {
    selectRole(id) {
      if (id === this.getActiveGame) {
        this.$store.commit("modal/SET_MODAL", {
          state: true,
          name: "role-select",
          props: { id },
        });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.role-select
  padding: 3.2rem 10.8rem
  @include xl
    padding: 2rem 4rem 2rem
  @include m
    padding: 2rem 1.6rem 2rem

.role-select__header
  display: flex
  gap: 2rem
  cursor: pointer
  align-items: center
  margin-bottom: 17.8rem
  @include xl
    margin-bottom: 10rem
  @include m
    margin-bottom: 4rem

.home__pixel
  z-index: 1
  max-width: 6.4rem
  max-height: 6.4rem
  @include xl
    max-width: 4rem
    max-height: 4rem

.role-select__cards
  display: flex
  width: 100%
  @include xl
    flex-wrap: wrap

.role-select__card
  display: flex
  flex-direction: column
  padding: 4rem 4rem 0
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.05)
  flex: 1 0 33%
  opacity: 0.5
  min-width: 30rem
  @include m
    padding: 4rem 1.6rem 0
  @include ms
    min-width: 28rem

.role-select__card.active
  opacity: 1
  border: 4px solid $green-main
  transition: $transition-speed
  cursor: pointer
  &:hover
    border: 4px solid $green-dark
  &:hover .card__start
    background-color: $green-dark

.card__title
  margin-bottom: 1.6rem

.card__description
  font-size: 1.4rem
  line-height: 2.2rem
  margin-bottom: 2.4rem

.card__start
  background-color: $green-main
  transition: $transition-speed
  padding: 15px 40px
  width: calc(100% + 8rem)
  transform: translate(-4rem, 0)
  margin-top: auto
  text-align: center
  color: $white
  @include m
    width: calc(100% + 3.2rem)
    transform: translate(-1.6rem, 0)

.role__icon
  max-width: 6.4rem
  max-height: 6.4rem
  margin-bottom: 2.4rem
</style>
